$(document).ready(function() {   
  $(".shop-contry-selector").change(function(){
    $.ajax({
      async: false,
      method: 'PUT',
      url: Spree.localizedPathFor('/api/v2/shipto'),
      data: {
        shipto: this.value
      },
      dataType: 'json'
    }).done(function(data) {
      location.reload();
    });
  });  
});